import React, {useEffect, useState} from 'react'
import {useMutation} from '@tanstack/react-query';
import axios from 'axios';
import {Apis} from '../../../utils/apis';
import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogOverlay,
  DialogTitle,
  FormControl,
  Input
} from '../../atoms';
import {LuChevronDown} from 'react-icons/lu';

const ComboBank = ({selectedBank, setSelectedBank}) => {

  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState('');
  const [banks, setBanks] = useState([]);

  const bankMutation = useMutation({
    mutationKey: ['op_get_banks'],
    mutationFn: (data) => axios.get(Apis.CAJA + '/bank', data),
    onSuccess: (response) => {
      localStorage.setItem('banks', JSON.stringify(response.data));
      setBanks(response.data);
    },
    onError: (error) => {
      console.log(error);
    }
  });

  const handleInputChange = (e) => {
    setQuery(e.target.value.toLowerCase());
  };

  const filteredBanks = banks.filter(bank =>
    bank.name.toLowerCase().includes(query)
  );

  const handleBankSelect = (bank) => {
    setSelectedBank(bank);
    setOpen(false);
  };

  useEffect(() => {
    const banksCache = JSON.parse(localStorage.getItem('banks'));
    if (banksCache) {
      setBanks(banksCache);
    } else {
      bankMutation.mutate();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <FormControl>
        <Button
          variant="outline"
          className='justify-between w-full'
          size='lg'
          onClick={() => setOpen(true)}
        >
          {selectedBank ?
            <span>{selectedBank.name}</span>
            :
            <span className='text-lg text-neutral-500'>Buscar...</span>
          }
          <LuChevronDown className="w-5 h-5 opacity-50 shrink-0" />
        </Button>
      </FormControl>

      <Dialog open={open} onOpenChange={setOpen}>
        <DialogOverlay />
        <DialogContent className='flex flex-col gap-4'>
          <DialogHeader>
            <DialogTitle>Seleccionar Banco</DialogTitle>
            <DialogDescription>
              Ingrese el nombre del banco
            </DialogDescription>
          </DialogHeader>
          <Input
            type="text"
            onChange={handleInputChange}
            placeholder="Buscar banco..."
          />

          <div className='flex flex-col max-h-[300px] overflow-y-auto overflow-x-hidden'>
            {filteredBanks.map((bank) => (
              <div
                key={bank.id}
                className='py-4 mx-4 text-lg border-b cursor-pointer border-neutral-500'
                onClick={() => handleBankSelect(bank)}
              >
                {bank.name}
              </div>
            ))}
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default ComboBank

import React, {useContext, useMemo, useState} from 'react'
import {Button, FormControl, Input} from '../../components/atoms'
import {BlockTitle, ComboFondo, DialogMoney, EmptyState, ReactTable} from '../../components/blocks'
import {useMutation, useQuery} from '@tanstack/react-query'
import axios from 'axios'
import {Apis} from '../../utils/apis'
import {toast} from '../../hooks/useToast'
import {SiteContext} from '../../context/SiteContext'
import {LuArrowBigRight, LuBanknote, LuListOrdered} from 'react-icons/lu'
import useConfirmDialog from '../../hooks/useConfirmDialog'
import {format, parseISO} from 'date-fns'
import {formatNumber} from '../../utils/general'
import {queryClient} from '../..'

const Transfers = () => {

  const {fondo, config, printer} = useContext(SiteContext);
  const [originFondo, setOriginFondo] = useState(fondo)
  const [destinyFondo, setDestinyFondo] = useState(null)
  const [amount, setAmount] = useState('')
  const [dollars, setDollars] = useState(null);
  const [openMoney, setOpenMoney] = useState(false);

  const transferMutation = useMutation({
    mutationKey: ['op_transfers'],
    mutationFn: (data) => axios.post(Apis.CAJA + '/fondos/transfer/v2', data),
    onSuccess: () => {
      setAmount('')
      setDestinyFondo(null)
      toast({
        title: "Operación realizada!",
        variant: 'success',
        duration: 2000
      })
      queryClient.invalidateQueries({queryKey: ['getTransfers']});
    },
    onError: (error) => {
      let errorMessage = 'Ha ocurrido un error en la solicitud';
      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      }
      toast({
        title: errorMessage,
        variant: 'danger',
        duration: 2000
      })
    }
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    if (fondo && amount && destinyFondo) {
      transferMutation.mutate({
        amount: amount,
        sourceFondoId: originFondo?.id,
        targetFondoId: destinyFondo?.id,
        printerId: printer?.printerId
      });
    }
  };

  const {data} = useQuery({
    queryKey: ['getTransfers', fondo?.id],
    queryFn: async () => await axios.get(Apis.CAJA + '/transfer/report?fondoId=' + fondo?.id)
      .then((res) => res.data)
      .catch(() => ({data: [], quantity: 0, totalAmount: 0})),
    enabled: !!fondo?.id
  })

  const deleteElement = (id) => axios.delete(Apis.CAJA + '/transfer/' + id);

  const deleteModal = useConfirmDialog(
    '¿Deseas eliminar esta operación?',
    'op_delete_transfer', //mutateKey
    'getTransfers', //volver a llamar para refrescar la data
    deleteElement // accion para ejecutar en el mutate
  );

  const confirmElement = (id, estado) => axios.put(Apis.CAJA + `/operation-transfer/${id}/status`, {status: estado});

  const confirmElementWrapper = (estado) => (id) => confirmElement(id, estado);

  const confirmTransfer = useConfirmDialog(
    '¿Deseas aceptar esta operación?',
    'op_confirm_transfer', //mutateKey
    'getTransfers', //volver a llamar para refrescar la data
    confirmElementWrapper(2) // accion para ejecutar en el mutate
  );

  const rejectTransfer = useConfirmDialog(
    '¿Deseas rechazar esta operación?',
    'op_reject_transfer', //mutateKey
    'getTransfers', //volver a llamar para refrescar la data
    confirmElementWrapper(3) // accion para ejecutar en el mutate
  );

  const columns = useMemo(
    () => [
      {
        header: 'Origin',
        id: 'sourceFondo',
        accessorFn: row => `${row.sourceFondo !== null ? row.sourceFondo : '--'}`,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: '',
        id: 'icon',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: info => (
          <span>
            <LuArrowBigRight size={28}/>
          </span>
        ),
      },
      {
        header: 'Destino',
        id: 'targetFondo',
        accessorFn: row => `${row.targetFondo !== null ? row.targetFondo : '--'}`,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Monto',
        accessorKey: 'amount',
        meta: {
          headerClassName: 'text-right',
          columnClassName: 'text-right',
        },
        cell: info => (
          <span className={info.row.original.sourceFondoId === fondo.id ? 'text-red-600' : 'text-success-500'}>
            {info.row.original.amount ? formatNumber(info.row.original.amount, config.symbol) : '--'}
          </span>
        ),
      },
      {
        header: 'Fecha',
        id: 'createdAt',
        accessorFn: row => `${row.createdAt ? format(parseISO(row.createdAt), "dd/MM hh:mm a") : '--'}`,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Acciones',
        accessorKey: 'actions',
        enableGlobalFilter: false,
        enableSorting: false,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: info => (
          <div className='flex flex-row justify-center gap-2'>
            {info.row.original.enabled === true &&
              <Button
                variant='outline'
                size='sm'
                onClick={() => deleteModal.handleClick(info.row.original.id)}
              >
                Eliminar
              </Button>
            }
          </div>
        ),
      }
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , [deleteModal, config, confirmTransfer, rejectTransfer]
  );

  return (
    <div className='flex flex-col flex-1 gap-4'>
      <BlockTitle title='TRANSFERENCIAS' className='m-0'/>
      <div className='flex flex-col self-stretch gap-6 p-4 bg-neutral-800 rounded-2xl'>
        <div className='flex flex-row items-end gap-4'>
          <FormControl className='flex-1'>
            <label>Origen</label>
            <ComboFondo selectedFondo={originFondo} setSelectedFondo={setOriginFondo}/>
          </FormControl>
          <div className='h-[50px] w-[50px] flex items-center justify-center'>
            <LuArrowBigRight size={28}/>
          </div>
          <FormControl className='flex-1'>
            <label>Destino</label>
            <ComboFondo selectedFondo={destinyFondo} setSelectedFondo={setDestinyFondo}/>
          </FormControl>
        </div>
        <FormControl className='flex-1'>
          <label>Monto</label>
          <Input
            placeholder="00.0"
            type='number'
            className='text-2xl font-semibold cursor-pointer'
            autoComplete="off"
            value={amount}
            readOnly
            required
            onChange={(e) => setAmount(e.target.value)}
            onClick={() => setOpenMoney(true)}
          />
        </FormControl>
        <Button size='lg' onClick={handleSubmit} disabled={transferMutation.isPending}>Guardar</Button>
      </div>
      <div className='flex flex-col self-stretch flex-1 gap-6 p-4 bg-neutral-800 rounded-2xl'>
        <div className='flex flex-row items-center gap-4'>
          <div className='flex flex-row items-center flex-1 gap-2 px-4 py-3 border border-neutral-700 rounded-2xl'>
            <div className='flex flex-col flex-1'>
              <span className='text-lg font-bold'>{data?.quantity ? formatNumber(data.quantity, null) : '--'}</span>
              <span className='text-xs font-light'>Nro de Operaciones</span>
            </div>
            <div className='p-3 bg-neutral-900 rounded-xl'>
              <LuListOrdered size={20}/>
            </div>
          </div>
          <div className='flex flex-row flex-1 gap-2 px-4 py-3 border border-neutral-700 rounded-2xl'>
            <div className='flex flex-col flex-1'>
              <span
                className='text-lg font-bold'>{data?.totalAmountIn ? formatNumber(data.totalAmountIn, config.symbol) : '--'}</span>
              <span className='text-xs font-light'>Ingresos</span>
            </div>
            <div className='p-3 bg-neutral-900 rounded-xl'>
              <LuBanknote size={20}/>
            </div>
          </div>
          <div className='flex flex-row flex-1 gap-2 px-4 py-3 border border-neutral-700 rounded-2xl'>
            <div className='flex flex-col flex-1'>
              <span
                className='text-lg font-bold'>{data?.totalAmountOut ? formatNumber(data.totalAmountOut, config.symbol) : '--'}</span>
              <span className='text-xs font-light'>Egresos</span>
            </div>
            <div className='p-3 bg-neutral-900 rounded-xl'>
              <LuBanknote size={20}/>
            </div>
          </div>
        </div>
        {data?.data && data?.data.length > 0 ?
          <ReactTable columns={columns} data={data?.data}/>
          :
          <EmptyState/>
        }
      </div>
      <deleteModal.ConfirmationModal/>
      <confirmTransfer.ConfirmationModal/>
      <rejectTransfer.ConfirmationModal/>
      <DialogMoney
        open={openMoney}
        setOpen={setOpenMoney}
        setAmount={setAmount}
        dollars={dollars}
        setDollars={setDollars}
      />
    </div>
  )
}

export default Transfers

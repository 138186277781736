import React, {createContext, useEffect, useMemo, useState} from 'react';
import {useQuery} from '@tanstack/react-query'
import axios from "axios";
import {Apis} from '../utils/apis';
import {FaSpinner} from 'react-icons/fa';
import {useWauth} from '../auth/WauthProvider';

export const SiteContext = createContext({
  salaId: null,
  setSalaId: () => {},
  sala: {},
  setSala: () => {},
  userInfo: {},
  config: {},
  setConfig: () => {},
  fondo: {},
  setFondo: () => {},
  printer: null,
  setPrinter: () => {},
  modeLocal: false,
  setModeLocal: () => {},
  resetSaleTicketForm: false,
  setResetSaleTicketForm: () => {},
});

export const SiteProvider = ({children}) => {

  const {isAuthenticated} = useWauth();
  const [salaId, setSalaId] = useState(null);
  const [sala, setSala] = useState({});
  const [userInfo, setUserInfo] = useState({});
  const [config, setConfig] = useState({});
  const [fondo, setFondo] = useState({});
  const [printer, setPrinter] = useState(null);
  const [modeLocal, setModeLocal] = useState(false);
  const [resetSaleTicketForm, setResetSaleTicketForm] = useState(false);

  const fetchUser = async () => {
    try {
      const response = await axios.get(`${Apis.USER}/users/me`, {
        params: {product: 'gaming'},
      });

      return response.data;
    } catch (error) {
      // Manejo de errores
      throw new Error('Error fetching user data');
    }
  };

  const {data, isPending} = useQuery({
    queryKey: ['getUser'],
    queryFn: fetchUser
  })

  useMemo(() => {
    if (data?.user !== undefined && data?.user !== null) {
      setUserInfo(data)
    }
  }, [data])


  useEffect(() => {
    const storedSalaId = localStorage.getItem('salaId');
    setSalaId(storedSalaId);

    const storedSala = JSON.parse(localStorage.getItem('sala'));
    setSala(storedSala);

    const storedFondo = JSON.parse(localStorage.getItem('fondo'));
    setFondo(storedFondo);

    const storedPrinter = JSON.parse(localStorage.getItem('printer'));
    setPrinter(storedPrinter);

    const storedModeLocal = JSON.parse(localStorage.getItem('modeLocal'))
    setModeLocal(storedModeLocal)

    const resetSaleTicketForm = JSON.parse(localStorage.getItem('resetSaleTicketForm'))
    setResetSaleTicketForm(resetSaleTicketForm)
    
  }, []);

  const {data: configData, isLoading: isConfigLoading} = useQuery({
    queryKey: ['getConfig', salaId],
    queryFn: () => axios.get(Apis.CAJA + '/config-caja', {headers: {'X-Sala-ID': salaId}})
      .then((res) => res.data),
    enabled: !!salaId // Solo realiza la consulta si salaId está definido
  })

  useEffect(() => {
    if (configData) {
      setConfig(configData);
    }
  }, [configData])

  if ((isPending || isConfigLoading) && isAuthenticated) {
    return (
      <div
        className='flex flex-col items-center justify-center w-full h-screen gap-4 select-none text-neutral-950 animate-pulse'>
        <FaSpinner className='animate-spin' size={24}/>
        <span>Cargando...</span>
      </div>
    );
  }

  return (
    <SiteContext.Provider
      value={{
        salaId,
        setSalaId,
        sala,
        setSala,
        userInfo,
        config,
        setConfig,
        fondo,
        setFondo,
        printer,
        setPrinter,
        modeLocal,
        setModeLocal,
        resetSaleTicketForm: resetSaleTicketForm,
        setResetSaleTicketForm: setResetSaleTicketForm
      }}
    >
      {children}
    </SiteContext.Provider>
  );
}
